<template>
  <v-form @submit.prevent="handleSubmit">
    <v-card>
      <translation-locales />

      <v-card-text>
        <v-row
          v-for="(branch, index) in branches"
          :key="index"
          class="mt-5"
        >
          <v-col
            v-show="index > 0"
            cols="12"
          >
            <v-divider></v-divider>
          </v-col>

          <v-col cols="6">
            <h3>Branch: {{ index + 1 }}</h3>
          </v-col>

          <v-col
            v-if="index > 0"
            cols="6"
            class="text-right"
          >
            <v-icon
              color="error"
              @click="deleteBranch(index)"
            >
              {{ icons.mdiDelete }}
            </v-icon>
          </v-col>

          <v-col cols="12">
            <translatable-input
              v-model="branch.name"
              label="Name"
            />
          </v-col>

          <v-col cols="12">
            <translatable-input
              v-model="branch.address"
              label="Address"
            />
          </v-col>

          <v-col cols="12">
            <district-selector
              v-if="cities.length"
              v-model="branch.district_id"
              :cities="cities"
            ></district-selector>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-switch
              v-model="branch.active"
              :label="branch.active ? 'Active' : 'Inactive'"
            ></v-switch>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            class="text-center"
          >
            <v-icon
              color="primary"
              @click="addBranch"
            >
              {{ icons.mdiPlusCircleOutline }}
            </v-icon>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn
              type="submit"
              class="mr-4"
              color="primary"
              :loading="loading"
              :disabled="loading"
            >
              Submit
            </v-btn>
            <v-btn
              type="reset"
              color="secondary"
              outlined
            >
              Reset
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { getCities } from '@/api/district'
import { bulkStoreBranches } from '@/api/merchant/branch'
import { DistrictSelector, TranslatableInput, TranslationLocales } from '@/components'
import { useConfirm, useNotifyErrors, useTranslatable } from '@/composables'
import { mdiDelete, mdiPlusCircleOutline } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'

export default {
  name: 'Branch',

  components: {
    DistrictSelector,
    TranslatableInput,
    TranslationLocales,
  },

  props: {
    merchantId: Number,
  },

  setup(props, { emit }) {
    const loading = ref(false)

    const cities = ref([])
    const branches = ref([])

    const getInitialBranchData = () => ({
      name: useTranslatable(),
      address: useTranslatable(),
      district_id: null,
      active: true,
    })

    // Get cities
    onMounted(async () => {
      addBranch()

      try {
        cities.value = await getCities()
      } catch (error) {
        useNotifyErrors(error)
      }
    })

    const addBranch = () => {
      branches.value.push(getInitialBranchData())
    }

    const deleteBranch = async targetIndex => {
      await useConfirm({
        title: 'Confirm remove branch?',
        content: 'This action cannot be reversed',
      })

      branches.value = branches.value.filter((branch, index) => index !== targetIndex)
    }

    const handleSubmit = async () => {
      loading.value = true

      try {
        const { status, data } = await bulkStoreBranches(props.merchantId, branches.value)
        if (status == 201) {
          emit('completed', data)
        }
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    }

    return {
      loading,
      cities,
      branches,

      addBranch,
      deleteBranch,
      handleSubmit,

      icons: {
        mdiPlusCircleOutline,
        mdiDelete,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.branch-block {
  min-height: 300px;
}
</style>

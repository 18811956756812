var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"merchantSettingsForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v("Notifications")])]),_c('v-col',{staticClass:"mr-auto",attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"items":_vm.locales,"item-value":"value","item-text":"name","outlined":"","hide-details":"","label":"Language"},model:{value:(_vm.settings.notification.locale.value),callback:function ($$v) {_vm.$set(_vm.settings.notification.locale, "value", $$v)},expression:"settings.notification.locale.value"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-combobox',{attrs:{"label":"Emails for New Order","multiple":"","outlined":"","required":"","hint":"Press tab to add a new tag","persistent-hint":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1"},[_vm._v(" "+_vm._s(item)+" ")])]}}]),model:{value:(_vm.settings.notification.new_order_recipients.value),callback:function ($$v) {_vm.$set(_vm.settings.notification.new_order_recipients, "value", $$v)},expression:"settings.notification.new_order_recipients.value"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-combobox',{attrs:{"label":"Emails for Cancel Order","multiple":"","outlined":"","required":"","hint":"Press tab to add a new tag","persistent-hint":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1"},[_vm._v(" "+_vm._s(item)+" ")])]}}]),model:{value:(_vm.settings.notification.cancel_order_recipients.value),callback:function ($$v) {_vm.$set(_vm.settings.notification.cancel_order_recipients, "value", $$v)},expression:"settings.notification.cancel_order_recipients.value"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v("Reports")])]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-combobox',{attrs:{"label":"Emails for Sales Report","multiple":"","outlined":"","required":"","hint":"Press tab to add a new tag","persistent-hint":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1"},[_vm._v(" "+_vm._s(item)+" ")])]}}]),model:{value:(_vm.settings.report.sales_report_recipients.value),callback:function ($$v) {_vm.$set(_vm.settings.report.sales_report_recipients, "value", $$v)},expression:"settings.report.sales_report_recipients.value"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-combobox',{attrs:{"label":"Email for Voucher Report","multiple":"","outlined":"","required":"","hint":"Press tab to add a new tag","persistent-hint":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1"},[_vm._v(" "+_vm._s(item)+" ")])]}}]),model:{value:(_vm.settings.report.voucher_report_recipients.value),callback:function ($$v) {_vm.$set(_vm.settings.report.voucher_report_recipients, "value", $$v)},expression:"settings.report.voucher_report_recipients.value"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"rules":[ _vm.between(_vm.settings.report.voucher_report_days.value, 1, 365) ],"outlined":"","label":"Generate per N days"},model:{value:(_vm.settings.report.voucher_report_days.value),callback:function ($$v) {_vm.$set(_vm.settings.report.voucher_report_days, "value", $$v)},expression:"settings.report.voucher_report_days.value"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-combobox',{attrs:{"label":"Email for Service Report","multiple":"","outlined":"","required":"","hint":"Press tab to add a new tag","persistent-hint":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1"},[_vm._v(" "+_vm._s(item)+" ")])]}}]),model:{value:(_vm.settings.report.service_report_recipients.value),callback:function ($$v) {_vm.$set(_vm.settings.report.service_report_recipients, "value", $$v)},expression:"settings.report.service_report_recipients.value"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"rules":[ _vm.between(_vm.settings.report.service_report_days.value, 1, 365) ],"outlined":"","label":"Generate per N days"},model:{value:(_vm.settings.report.service_report_days.value),callback:function ($$v) {_vm.$set(_vm.settings.report.service_report_days, "value", $$v)},expression:"settings.report.service_report_days.value"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-combobox',{attrs:{"label":"Email for Tangible Report","multiple":"","outlined":"","required":"","hint":"Press tab to add a new tag","persistent-hint":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1"},[_vm._v(" "+_vm._s(item)+" ")])]}}]),model:{value:(_vm.settings.report.tangible_report_recipients.value),callback:function ($$v) {_vm.$set(_vm.settings.report.tangible_report_recipients, "value", $$v)},expression:"settings.report.tangible_report_recipients.value"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"rules":[ _vm.between(_vm.settings.report.tangible_report_days.value, 1, 365) ],"outlined":"","label":"Generate per N days"},model:{value:(_vm.settings.report.tangible_report_days.value),callback:function ($$v) {_vm.$set(_vm.settings.report.tangible_report_days, "value", $$v)},expression:"settings.report.tangible_report_days.value"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-divider')],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"mr-4",attrs:{"type":"submit","color":"primary","loading":_vm.loading,"disabled":_vm.loading}},[_vm._v(" Submit ")]),_c('v-btn',{attrs:{"type":"reset","color":"secondary","outlined":""}},[_vm._v(" Reset ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-form @submit.prevent="handleSubmit">
    <v-card>
      <translation-locales />

      <v-card-text>
        <v-row class="mt-5">
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="form.code"
              outlined
              hide-details
              label="Code"
              :placeholder="'e.g. MIXCARE'"
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-autocomplete
              v-model="form.channel_id"
              label="(Optional) Owned by Channel"
              :items="channelOptions"
              item-text="title"
              item-value="value"
              outlined
              clearable
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <translatable-input
              v-model="form.name"
              label="Name"
              class="mt-5"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <translatable-editor
              v-model="form.introduction"
              label="Introduction"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col
            md="12"
            cols="12"
          >
            <v-menu
              v-model="showDatePicker"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.joined_at"
                  readonly
                  v-bind="attrs"
                  label="Join Date"
                  outlined
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.joined_at"
                color="primary"
                @input="showDatePicker = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <CountryIdFilter
              v-model="form"
              :clearable="false"
              @country-changed="country => form.time_zone_id = country.main_timezone.id"
            />
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <time-zone-selector
              v-model="form.time_zone_id"
              :disabled="!form.country_id"
              @update="value => form.time_zone_id = value"
            ></time-zone-selector>
          </v-col>
        </v-row>

        <div class="mt-4">
          <v-btn
            type="submit"
            class="mr-4"
            color="primary"
            :loading="loading"
            :disabled="loading"
          >
            Submit
          </v-btn>
          <v-btn
            type="reset"
            color="secondary"
            outlined
          >
            Reset
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import MerchantAPI from '@/api/merchant/merchant'
import {
  CountryIdFilter,
  TimeZoneSelector, TranslatableEditor, TranslatableInput, TranslationLocales,
} from '@/components'
import { useTranslatable } from '@/composables'

import channelStore from '@/modules/channel/store'
import { t } from '@/plugins/i18n'
import store from '@/store'
import { computed, ref } from '@vue/composition-api'

export default {
  name: 'BasicInfo',

  components: {
    TranslatableInput,
    TranslatableEditor,
    TranslationLocales,
    TimeZoneSelector,
    CountryIdFilter,
  },

  setup(props, { emit }) {
    const loading = ref(false)
    const showDatePicker = ref(false)

    const initialData = {
      code: null,
      name: useTranslatable(),
      introduction: useTranslatable(),
      time_zone_id: null,

      // logo: null,
      channel_id: null,
      country_id: null,
      joined_at: null,
      active: true,
    }

    const channelOptions = computed(() => channelStore.state.channels.map(ch => ({ title: `${t(ch.name)} (${ch.code})`, value: ch.id })))

    const form = ref({ ...initialData })

    const handleSubmit = async () => {
      loading.value = true

      const data = await MerchantAPI.create(form.value)

      if (data !== null) {
        store.dispatch('merchant/fetchAllMerchants')
        emit('completed', data)
      }

      loading.value = false
    }

    return {
      channelOptions,
      loading,
      form,
      handleSubmit,
      showDatePicker,
    }
  },
}
</script>

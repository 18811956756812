<template>
  <v-form
    ref="merchantSettingsForm"
    v-model="valid"
    lazy-validation
    @submit.prevent="handleSubmit"
  >
    <v-container>
      <v-row>
        <v-col cols="12">
          <h3>Notifications</h3>
        </v-col>

        <v-col
          cols="12"
          md="4"
          class="mr-auto"
        >
          <v-select
            v-model="settings.notification.locale.value"
            :items="locales"
            item-value="value"
            item-text="name"
            outlined
            hide-details
            label="Language"
          ></v-select>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-combobox
            v-model="settings.notification.new_order_recipients.value"
            label="Emails for New Order"
            multiple
            outlined
            required
            hint="Press tab to add a new tag"
            persistent-hint
          >
            <template #selection="{ item }">
              <v-chip class="mt-1">
                {{ item }}
              </v-chip>
            </template>
          </v-combobox>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-combobox
            v-model="settings.notification.cancel_order_recipients.value"
            label="Emails for Cancel Order"
            multiple
            outlined
            required
            hint="Press tab to add a new tag"
            persistent-hint
          >
            <template #selection="{ item }">
              <v-chip class="mt-1">
                {{ item }}
              </v-chip>
            </template>
          </v-combobox>
        </v-col>

        <v-col cols="12">
          <h3>Reports</h3>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-combobox
            v-model="settings.report.sales_report_recipients.value"
            label="Emails for Sales Report"
            multiple
            outlined
            required
            hint="Press tab to add a new tag"
            persistent-hint
          >
            <template #selection="{ item }">
              <v-chip class="mt-1">
                {{ item }}
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-combobox
            v-model="settings.report.voucher_report_recipients.value"
            label="Email for Voucher Report"
            multiple
            outlined
            required
            hint="Press tab to add a new tag"
            persistent-hint
          >
            <template #selection="{ item }">
              <v-chip class="mt-1">
                {{ item }}
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="settings.report.voucher_report_days.value"
            :rules="[ between(settings.report.voucher_report_days.value, 1, 365) ]"
            outlined
            label="Generate per N days"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-combobox
            v-model="settings.report.service_report_recipients.value"
            label="Email for Service Report"
            multiple
            outlined
            required
            hint="Press tab to add a new tag"
            persistent-hint
          >
            <template #selection="{ item }">
              <v-chip class="mt-1">
                {{ item }}
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="settings.report.service_report_days.value"
            :rules="[ between(settings.report.service_report_days.value, 1, 365) ]"
            outlined
            label="Generate per N days"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-combobox
            v-model="settings.report.tangible_report_recipients.value"
            label="Email for Tangible Report"
            multiple
            outlined
            required
            hint="Press tab to add a new tag"
            persistent-hint
          >
            <template #selection="{ item }">
              <v-chip class="mt-1">
                {{ item }}
              </v-chip>
            </template>
          </v-combobox>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="settings.report.tangible_report_days.value"
            :rules="[ between(settings.report.tangible_report_days.value, 1, 365) ]"
            outlined
            label="Generate per N days"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-divider></v-divider>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-btn
            type="submit"
            class="mr-4"
            color="primary"
            :loading="loading"
            :disabled="loading"
          >
            Submit
          </v-btn>
          <v-btn
            type="reset"
            color="secondary"
            outlined
          >
            Reset
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { storeSettings } from '@/api/merchant/setting'
import { useNotifyErrors } from '@/composables'
import appStore from '@/modules/app/store'
import { between } from '@core/utils/validation'
import { computed, ref } from '@vue/composition-api'

export default {
  name: 'Setting',

  props: {
    merchantId: {
      type: Number,
      required: true,
    },
  },

  setup(props, { emit }) {
    const merchantSettingsForm = ref()
    const loading = ref(false)
    const valid = ref(true)

    const rules = {
      email: [v => !!v || 'Required', v => /.+@.+\..+/.test(v) || 'Please enter valid format'],
    }

    const locales = computed(() => appStore.state.info.locales)

    const settings = ref({
      notification: {
        locale: {
          value: 'en',
          value_type: 'string',
        },
        new_order_recipients: {
          value: null,
          value_type: 'array',
        },
        cancel_order_recipients: {
          value: null,
          value_type: 'array',
        },
      },
      report: {
        sales_report_recipients: {
          value: null,
          value_type: 'array',
        },
        voucher_report_days: {
          value: 7,
          value_type: 'integer',
        },
        voucher_report_recipients: {
          value: null,
          value_type: 'array',
        },
        service_report_days: {
          value: 1,
          value_type: 'integer',
        },
        service_report_recipients: {
          value: null,
          value_type: 'array',
        },
        tangible_report_days: {
          value: 1,
          value_type: 'integer',
        },
        tangible_report_recipients: {
          value: null,
          value_type: 'array',
        },
      },
    })

    const formatData = data => {
      const result = []

      for (const [category, object] of Object.entries(data)) {
        for (const [key, value] of Object.entries(object)) {
          result.push({
            category,
            key,
            value: value.value,
            value_type: value.value_type,
          })
        }
      }

      return result
    }

    const handleSubmit = async () => {
      if (merchantSettingsForm.value.validate()) {
        try {
          loading.value = true

          const data = await storeSettings(props.merchantId, {
            settings: formatData(settings.value),
          })

          if (data !== null) {
            emit('completed', data)
          }
        } catch (error) {
          useNotifyErrors(error)
        } finally {
          loading.value = false
        }
      }
    }

    return {
      merchantSettingsForm,
      loading,
      valid,
      rules,
      locales,

      settings,
      handleSubmit,
      between,
    }
  },
}
</script>

<style lang="scss" scoped>
.branch-block {
  min-height: 300px;
}
</style>

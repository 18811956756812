<template>
    <v-stepper alt-labels class="mt-12" v-model="currentStep">
      <v-stepper-header>
        <v-stepper-step step="1" :complete="currentStep > 1"> Basic </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="2" :complete="currentStep > 2"> Branches </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="3" :complete="currentStep > 3"> Settings </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="4" :complete="currentStep == 4"> Completed </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <basic-info @completed="merchantCreated"></basic-info>
        </v-stepper-content>

        <v-stepper-content step="2">
          <branch :merchant-id="merchantId" @completed="branchCreated"></branch>
        </v-stepper-content>

        <v-stepper-content step="3">
          <setting :merchant-id="merchantId" @completed="settingCreated"></setting>
        </v-stepper-content>

        <v-stepper-content step="4">
          <v-row>
            <v-col cols="12" class="text-center">
              Completed
            </v-col>

            <v-col class="text-center mt-10">
              <v-btn
                color="primary"
                class="mb-4 me-3"
                @click="updateStoreMerchant"
                :to="{name:'merchant-overview', params: {id: merchantId}}"
              >
              Go To Merchant Overview
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import { ref } from '@vue/composition-api'

import store from '@/store'
import BasicInfo from '../components/forms/BasicInfo.vue'
import Branch from '../components/forms/Branch'
import Setting from '../components/forms/Setting'

export default {
  components: {
    BasicInfo,
    Branch,
    Setting,
  },

  setup() {
    const currentStep = ref(1)
    const merchantId = ref(null)

    const merchantCreated = merchant => {
      currentStep.value = 2
      merchantId.value = merchant?.id
    }

    const branchCreated = () => {
      currentStep.value = 3
    }

    const settingCreated = () => {
      currentStep.value = 4
    }
    const updateStoreMerchant = () => {
      store.dispatch('merchant/fetchAllMerchants')
    }

    return {
      currentStep,
      merchantId,
      merchantCreated,
      branchCreated,
      settingCreated,
      updateStoreMerchant,
    }
  },
}
</script>
